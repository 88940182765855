import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
import { Box } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { alumnoDemoCreadaTutea, getAlumnosDemo } from '../../../../services/alumnos';
import { DataGridPro, esES, GridActionsCellItem } from '@mui/x-data-grid-pro';
import Moment from 'react-moment';

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosDemoContent() {
    const classes = useStyles();

    const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    // const [selectionModel, setSelectionModel] = React.useState([]);
    // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // const [comerciales, setComerciales] = useState(undefined)

    const fetchAlumnos = () => {
      const usuario_id = usuarioSeleccionado.id
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")
        getAlumnosDemo({ usuario_id: usuario_id }).then((response) => {
          setLoading(false)

          response && setAlumnos(response?.alumnos)
        });
    }
    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        fetchAlumnos()

        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'nombre', headerName: 'Nombre', minWidth: 250 },
      { field: 'correo_electronico', headerName: 'Correo', minWidth: 450 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
      { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
      {
        'field': 'demo_fecha', 
        'width':130, 
        'headerName': 'Fecha envio', 
        'description': 'Fecha de envio de la demo',
        // type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        'field': 'demo_fecha_creacion_tutea', 
        'width':130, 
        'headerName': 'Fecha tutea', 
        'description': 'Fecha de creación de la demo en tutea',
        // type: 'date',
        renderCell: (cellValues) => {
          console.log(cellValues.value)
          return (
            
              (cellValues.value !== null && cellValues.value !== undefined && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 90 },
      { field: 'demo_usuario_nombre', headerName: 'Comercial',minWidth: 200, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            label="Demo creada en tutea"
            onClick={ () => { 
              alumnoDemoCreadaTutea({ usuario_id: usuarioSeleccionado.id, alumno_id: params.id }).then((response) => {

                  fetchAlumnos()

                  Swal.fire({
                    title: 'Demo creada en Tutea',
                    text: 'La demo creada en Tutea ha sido registrada correctamente',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#007bff',
                  })
              })
            }}
            showInMenu
          />,
        ],
      }
    ]

    const Contenido = () => {
      const [selection, setSelection] = useState([])

      // const handleAlumnoDetalle = (id) => {
      //   history.push('/alumnos_tutor/detalle/' + id )
      // }

      const [openModalDifusion, setOpenModalDifusion] = useState(false)
      const handleCerrarDialogoNotificacion = () => {
          setOpenModalDifusion(false)
      }
      const handleDialogoNotificacion = () => {
        setOpenModalDifusion(true)
      }
      return (
          alumnos ?
              <>
                  <TituloPagina titulo={"Envíos de demos"} />
                  
                  <Box>
                    <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                    
                        <DataGridPro
                          pagination={false}
                          rows={alumnos} 
                          columns={columnas} 
                          rowHeight={30}
                          checkboxSelection
                          disableSelectionOnClick
                          disableDensitySelector
                          // onStateChange={ (item) => setSelection(item.rowSelection) }
                          localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                          // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                        />

                    </div>
                  </Box>
              </>
              
              :
              
              <EmptyComponent mensaje="No hay datos para mostrar" />
      )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
