import { Button, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Stack } from '@mui/material'
import React, { useRef, useState } from 'react'
import { entregaPlantillaLineaAdjuntoDelete } from '../../services/alumnos'
import { useSelector } from 'react-redux'
import { selectUsuarioSeleccionado } from '../../redux/reducer/loginReducer'

const AdjuntoCorreoMasivo = ( { adjunto_nombre_original, setFieldValue }) => {
    // const [adjuntoInterno, setAdjuntoInterno] = useState(adjunto)
    const [adjuntoNombreOriginalInterno, setAdjuntoNombreOriginalInterno] = useState(adjunto_nombre_original)
    const ref = useRef();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const handleChange = ({ target }) => {

        setAdjuntoNombreOriginalInterno(target.files[0])
        setFieldValue('adjunto', target.files[0])
        setFieldValue('adjunto_nombre_original', target.files[0].name)

    }

    const handleQuitar = () => {

        // entregaPlantillaLineaAdjuntoDelete({usuario_id: usuarioSeleccionado.id, plantilla_linea_id: plantilla_linea_id}).then( (response ) => {
        //     setAdjuntoNombreOriginalInterno(null)
        // })
        setAdjuntoNombreOriginalInterno(null)
        ref.current.value = "";
    }

  return (
    <>
        <input
            // accept="image/*"
            accept="application/msword, application/pdf"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={ handleChange }
            ref={ref}
        />
        <Stack direction='row' spacing={2}>
            
            { !adjuntoNombreOriginalInterno && 
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" >
                    Añadir adjunto
                </Button>
            </label> }

            <Stack direction='row' spacing={1}>
                { adjuntoNombreOriginalInterno && <Typography style={{paddingTop: 6, fontWeight: 700}}>Adjunto: </Typography> }
                { adjuntoNombreOriginalInterno && <Typography style={{paddingTop: 6}}>{adjunto_nombre_original}</Typography> }
                { adjuntoNombreOriginalInterno && <Button style={{color: "#ff0000"}} onClick={handleQuitar}>Quitar</Button> }
            </Stack>

        </Stack>
    </>
  )
}

export default AdjuntoCorreoMasivo
