import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
import { Box, Button, Chip, Grid, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { getAlumnosDemo, getAlumnosRegistrados } from '../../../../services/alumnos';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import { amber, blue, green, red } from '@material-ui/core/colors';
import { DataGridPro, esES } from '@mui/x-data-grid-pro';
import SendIcon from '@mui/icons-material/Send';
import DialogCorreoMasivo from './DialogCorreoMasivo';
import Moment from 'react-moment';

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosDemoContent() {
    const classes = useStyles();

    const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    // const [selectionModel, setSelectionModel] = React.useState([]);
    // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosDemo({ usuario_id: usuario_id }).then((response) => {
          setLoading(false)

          response && setAlumnos(response?.alumnos)
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'nombre', headerName: 'Nombre', minWidth: 250 },
      { field: 'demo_usuario_nombre', headerName: 'Comercial',minWidth: 200, flex: 1 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
      { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 90 },
      { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
      {
        'field': 'demo_fecha', 
        'width':130, 
        'headerName': 'Fecha envio', 
        'description': 'Fecha de envio de la demo',
        // type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
    ]

    const Contenido = () => {
      const [selection, setSelection] = useState([])

      // const handleAlumnoDetalle = (id) => {
      //   history.push('/alumnos_tutor/detalle/' + id )
      // }

      const [openModalDifusion, setOpenModalDifusion] = useState(false)
      const handleCerrarDialogoNotificacion = () => {
          setOpenModalDifusion(false)
      }
      const handleDialogoNotificacion = () => {
        setOpenModalDifusion(true)
      }
      return (
          alumnos ?
              <>
                  <TituloPagina titulo={"Envíos de demos"} />

                  <Box style={{padding: '0 15px 10px 15px'}}>
                    { selection.length > 0 && <Button size='small' variant="outlined" endIcon={<SendIcon />} onClick={ () => handleDialogoNotificacion() }>
                      Enviar difusión
                    </Button>}
                  </Box>
                  <Box>
                    <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                    
                        <DataGridPro
                          pagination={false}
                          rows={alumnos} 
                          columns={columnas} 
                          rowHeight={30}
                          checkboxSelection
                          disableSelectionOnClick
                          disableDensitySelector
                          onStateChange={ (item) => setSelection(item.rowSelection) }
                          localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                          // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                        />

                    </div>
                  </Box>

                  <DialogCorreoMasivo 
                    openModal={openModalDifusion}
                    handleCerrarDialogo={handleCerrarDialogoNotificacion}
                    alumnosSeleccionados={selection}
                  />
              </>
              
              :
              
              <EmptyComponent mensaje="No hay datos para mostrar" />
      )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
