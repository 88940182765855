import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer'
import { enviarCorreoMasivo, enviarDifusion } from '../../../../services/alumnos'
import AdjuntoCorreoMasivo from '../../../../components/AdjuntoEntrega/AdjuntoCorreoMasivo'
import * as yup from 'yup';
import { useFormik } from 'formik'

const DialogCorreoMasivo = ({openModal, handleCerrarDialogo, alumnosSeleccionados}) => {
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const [initialValues, setInitialValues] = useState({
        asunto: '',
        descripcion: '',
        adjunto: '',
        adjunto_nombre_original: '',
        // fecha_aviso:  null
    })
    const validationSchema = yup.object({
        
        asunto: yup.string().required('El asunto es requerido'),
        descripcion: yup.string().required('La descripción es requerida'),
        
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            
            enviarCorreoMasivo({
                usuario_id: usuarioSeleccionado.id, 
                asunto: values.asunto, 
                descripcion: values.descripcion, 
                adjunto: values.adjunto, 
                alumnosSeleccionados: alumnosSeleccionados}).then( (response) => {
                    
                    _handleCerrarDialogo()

                })
            
        }
    })

    const _handleCerrarDialogo = () => {
        formik.setFieldValue('asunto','')
        formik.setFieldValue('descripcion','')
        formik.setFieldValue('adjunto',null)
        formik.setFieldValue('adjunto_nombre_original',null)
                            
        handleCerrarDialogo()
    }

    return (
        <Dialog open={openModal} onClose={() => _handleCerrarDialogo() } maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>
                <DialogTitle>Nuevo mensaje masivo</DialogTitle>
                <DialogContent style={{width: '700px'}}>

                    <Grid xs={12}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            // error={errorDescripcion}
                        >
                            <TextField
                                autoFocus
                                id="asunto"
                                type="text"
                                fullWidth
                                variant="standard"
                                label="Asunto"
                                value={formik.values.asunto}
                                onChange={ formik.handleChange }
                                error={formik.touched.asunto && Boolean(formik.errors.asunto)}
                                helperText={formik.touched.asunto && formik.errors.asunto}
                                
                            />

                        </FormControl>
                    </Grid>
                    <Grid xs={12} style={{marginTop: 20}}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                        >
                            <TextField
                                
                                id="descripcion"
                                type="text"
                                fullWidth
                                variant="standard"
                                label="Texto de la notificación"
                                multiline
                                minRows={5}
                                value={formik.values.handleChange}
                                onChange={ formik.handleChange }
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                                
                            />

                        </FormControl>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 10}}>
                        <AdjuntoCorreoMasivo
                            // plantilla_linea_id={entrega?.id} 
                            adjunto={formik.values.adjunto} 
                            adjunto_nombre_original={formik.values.adjunto_nombre_original} 
                            setFieldValue={formik.setFieldValue} 
                        />
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => _handleCerrarDialogo()}>Cancelar</Button>
                    <Button type='submit'>Enviar mensaje</Button>
                </DialogActions>
            </form>
        </Dialog>
  )
}

export default DialogCorreoMasivo
