import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlertNotificaciones from '../../../components/AlertNotificaciones/AlertNotificaciones';
import AlumnosDemoContent from './components/AlumnosDemoContent';

const AlumnosDemo = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <AlumnosDemoContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosDemo;